import { useEffect, useState } from "react";
import clsx from "clsx";
import React from "react";
import {
    AppBar,
    Link,
    Button,
    CssBaseline,
    List,
    ListItem,
    ListItemIcon,
    Drawer,
    Divider,
    IconButton,
    Toolbar,
    Typography,
    Grid,
    InputLabel,
    TextField,
    Container,
    Select,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Switch,
    FormControlLabel,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from '@mui/icons-material/Logout';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Person from "@mui/icons-material/Person";
import searchApi from "../api/search";
import userApi from "../api/user"
import { usePaperTableStyles, usePaperViewStyles } from "../styles/paperViewStyles";
import Copyright from "../components/Copyright";
import { useAuth } from "../useAuth";
import Dashboard from '@mui/icons-material/Dashboard';

const convertNiceDate = (badDate) => {
    let date = new Date(badDate);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
        dt = "0" + dt;
    }
    if (month < 10) {
        month = "0" + month;
    }

    return year + "-" + month + "-" + dt;
};

const SearchView = () => {
    const classes = usePaperViewStyles();
    const classes2 = usePaperTableStyles();
    const [drawerToggled, setDrawerToggled] = useState(false);
    const { user, logout } = useAuth();
    const [searchInput, setSearchInput] = useState("");
    const [searchParam, setSearchParam] = useState("title");
    const [results, setResults] = useState([]);
    const [debouncedQuery, setDebouncedQuery] = useState(searchInput);
    const groups = userApi.getUserGroups(user.id);

    const handleDrawerToggle = () => {
        setDrawerToggled(!drawerToggled);
    };

    let inputHandler = (e) => {
        setSearchInput(e.target.value);
    };

    const searchParamChange = (event) => {
        setSearchParam(event.target.value);
    };

    const containsAuthor = (authorsList, searchInput) => {
        for (let i = 0; i < authorsList.length; i++) {
            if (authorsList[i].toLowerCase().includes(searchInput.toLowerCase())) {
                return true;
            }
        }
        return false;
    };

    // get filtered papers on mount and everytime filter state is updated
    // useEffect(() => {
    //     async function apiCalls() {

    //         await paperApi.getList(filter).then((paperList) => {
    //             if (paperList) {
    //                 setPapers(paperList);
    //             }
    //             else {
    //                 setPapers([]);
    //             }
    //         });

    //         /*
    //         * NOTE: this is where queryFilters and Filters should go (filters being a map from
    //         * each filter field to filter value(s)). searchInput should work, but searchParam
    //         * only can do by title or author.
    //         * 
    //         * TODO: add an actual filter option for the user to enter in what they wish to
    //         * filter by, and collect and send that data here
    //         */
    //         await searchApi.getList(searchInput, searchParam, "").then((results) => {
    //             if (results) {
    //                 console.log(results);
    //             }
    //         });
    //     }

    //     apiCalls();
    // }, []);

    useEffect(() => {
        const delay = setTimeout(() => {
            setDebouncedQuery(searchInput);
        }, 500);

        return () => clearTimeout(delay);
    }, [searchInput]);

    useEffect(() => {
        const fetchResults = () => {
            searchApi.getList(debouncedQuery, searchParam, null, null, null, null, user.id, groups).then(response => {
                console.log(response);
                setResults(response);
            });
            // const response = await axios.post('http://localhost:5000/search', {
            //   query: debouncedQuery,
            //   field: searchParam,
            // });
            // setResults(response.data.hits.hits);

        };

        if (debouncedQuery) {
            fetchResults();
        } else {
            setResults([]);
        }
    }, [debouncedQuery, searchParam]);

    useEffect(() => {
        console.log(results);
    }, [results])


    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, drawerToggled && classes.appBarShift)}
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                        className={clsx(
                            classes.menuButton,
                            drawerToggled && classes.menuButtonHidden
                        )}
                        size="large"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        {"Search All Papers"}
                    </Typography>
                    <Button
                        variant="outlined"
                        color="inherit"
                        startIcon={<Person />}
                        href="/userprofile"
                    >
                        {user.firstName}
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<LogoutIcon />}
                        onClick={logout}
                    >
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(
                        classes.drawerPaper,
                        !drawerToggled && classes.drawerPaperClose
                    ),
                }}
                open={drawerToggled}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerToggle} size="large">
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <Dashboard />
                        </ListItemIcon>
                        <Link href="/papers" underline="hover">
                            Dashboard
                        </Link>
                    </ListItem>
                </List>
            </Drawer>
            <div className={classes.papersTable}>
                <div className={classes2.appBarSpacer}>
                    <Container maxWidth="lg" className={classes2.container}>
                        <Grid container spacing={3}>

                            <Grid item xs={10} md={10} lg={10}>
                                <InputLabel>Search</InputLabel>
                                <TextField
                                    id="outlined-basic"
                                    onChange={inputHandler}
                                    variant="outlined"
                                    fullWidth
                                />

                            </Grid>
                            <Grid item xs={2} md={2} lg={2}>
                                <InputLabel>Search By</InputLabel>
                                <Select
                                    value={searchParam}
                                    label="Search By"
                                    onChange={searchParamChange}
                                >
                                    <MenuItem value={"title"}>Document Title</MenuItem>
                                    <MenuItem value={"authors"}>Document Author</MenuItem>
                                    <MenuItem value={"full_text"}>Document Text</MenuItem>
                                </Select>
                            </Grid>
                            {searchInput !== "" ? (
                                <Grid item xs={12} md={12} lg={12}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Document Title</TableCell>
                                                <TableCell>Document Author</TableCell>
                                                <TableCell>Most Recent Edit</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {results.map((results, index) => (
                                                <TableRow key={index}>
                                                    {searchInput !== "" ? (
                                                        <TableCell>
                                                            <Link
                                                                href={
                                                                    "/" + results._source["id"] + "/" + results._source["version_number"]
                                                                }
                                                                underline="hover"
                                                            >
                                                                {results._source["title"]}
                                                            </Link>
                                                        </TableCell>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {searchInput !== "" ? (
                                                        <TableCell>
                                                            <React.Fragment>{results._source["authors"].replace(/[\{\}]/g, '')}</React.Fragment>
                                                        </TableCell>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {searchInput !== "" ? (
                                                        <TableCell>
                                                            {convertNiceDate(results._source["updated_at"])}
                                                        </TableCell>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </Container>
                </div>
            </div>


            <div pt={4}>
                <Copyright />
            </div>
        </div>
    );
};
export default SearchView;