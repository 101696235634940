import axios from "axios";


export const apiUrl = process.env.REACT_APP_API_URL + "/"; 
//this one needs a slash at the end for reasons,
//but the apiUrl in src/views/admin-ui/dataprovider.js doesn't

export const api = (otherUrl) => {

    //TODO: Figure out why this is running when loading login screen (before logging in)
    const userInfo = (localStorage.getItem("userInfo") != null) ? JSON.parse(localStorage.getItem("userInfo")) : {};

    let useUrl;
    if (typeof otherUrl != "undefined") {
        return axios.create({
            baseURL: otherUrl
        });
    } else {
        return axios.create({
            baseURL: apiUrl,
            headers: { Authorization: `Bearer ${userInfo.token}`}
        });
    }

    return axios.create({
        baseURL: useUrl,
        headers: { Authorization: `Bearer ${userInfo.token}`}
    });
};

export const authApi = () => {
    return axios.create({
        baseURL: apiUrl,
    });
};

export const setApiToken = (token) => {
    api().defaults.headers.common["Authorization"] = `Bearer ${token}`;
};
