import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/TextField';
import { Box } from '@mui/material';
import NewInstruction from './NewInstruction';
import NewTrigger from './NewTrigger';
import adminDashApi from '../../api/adminDash';


//import adminDashApi from '../../api/adminDash';

export default function BotMenu(props) {
    const [anchorElName, setAnchorElName] = React.useState(null);
    const [anchorElEnabled, setAnchorElEnabled] = React.useState(null);
    const [type, setType] = React.useState(props.provider);
    const [enabled, setEnabled] = React.useState(props.enabled);
    const [values, setValues] = React.useState({
        model_name: props.model_name,
        openai_api_key: props.openai_api_key,
        huggingfacehub_api_token: props.huggingfacehub_api_token,
        temperature: props.temperature,
    });
    const [submitted, setSubmitted] = React.useState(false);

    const formatInstructions = () => {
        var formattedInstructions = []
        for (const [key, value] of Object.entries(props.instructions)) {
            formattedInstructions.push([key, value[0], value[1]]);
        }

        return formattedInstructions;
    };
    const [instructionsList, setInstructions] = React.useState(formatInstructions());
    const [triggers, setTriggers] = React.useState(props.triggers);

    const open = Boolean(anchorElName);
    const openEnabled = Boolean(anchorElEnabled);

    // Model Name Handlers
    const handleClickName = (event) => {
        setAnchorElName(event.currentTarget);
    };
    const handleCloseName = () => {
        setAnchorElName(null);
    };
    const handleSelectName = (option) => {
        setType(option);
        handleCloseName();
    };

    // Bot Enabled Handlers
    const handleClickEnabled = (event) => {
        setAnchorElEnabled(event.currentTarget);
    };
    const handleCloseEnabled = () => {
        setAnchorElEnabled(null);
    };
    const handleSelectEnabled = (option) => {
        setEnabled(option);
        handleCloseEnabled();
    }

    // Misc Text or Number Value Change Handlers
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value }); 
    }; 

    // Instruction Handlers
    const addInstruction = () => {
        setInstructions([...instructionsList, {
            title: "",
            map_prompt: "",
            combined_prompt: "",
        }]);
    };

    const handleInstructionChange = (id, newInstruction) => {
        let newInstructions = [...instructionsList];
        newInstructions[id] = [newInstruction["title"], newInstruction["map_prompt"], newInstruction["combined_prompt"]];

        setInstructions(newInstructions);
    };

    // Trigger Handlers
    const addTrigger = () => {
        setTriggers([...triggers, ""]);
    };

    const handleTriggerChange = (id, newTrigger) => {
        let newTriggers = [...triggers];
        newTriggers[id] = newTrigger;

        setTriggers(newTriggers);
    }

    const submitChanges = async () => {
        var instructions = {};
        for(var i = 0; i < instructionsList.length; i++)
        {
            instructions[instructionsList[i][0]] = [instructionsList[i][1], instructionsList[i][2]]
        }

        const changes = {
            "enabled" : enabled,
            "triggers" : triggers,
            "model_provider" : type,
            "model_name" : values["model_name"],
            "openai_api_key" : values["openai_api_key"],
            "huggingfacehub_api_token" : values["huggingfacehub_api_token"],
            "temperature" : values["temperature"],
            instructions,
        };
        adminDashApi.submit(changes, "summary").then(() => { setSubmitted(true) });
    };

    return (
        <div className='adminDashboard'>
            <Box 
                sx={{
                    m: 2,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gridTemplateRows: 'auto',
                    gridTemplateAreas: `
                        "enabled . . ."
                        "provider . . ."
                        "name . . ."
                        "apiKey apiToken . ."
                        "temperature . . ."
                        "instructions instructions instructions instructions"
                        "trigger trigger trigger trigger"
                        "submit submitted . ."
                    `,
                }}
            >
                <Box sx={{ gridArea: 'enabled'}}>
                    Enabled:
                    
                    <Button
                        id="type-example-button"
                        varient="text"
                        size="medium"
                        aria-controls={openEnabled ? 'type-example-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openEnabled ? 'true' : undefined}
                        onClick={handleClickEnabled}
                    >
                        {enabled}
                    </Button>
                        
                    <Menu
                        id="type-example-menu"
                        aria-labelledby="type-example-button"
                        anchorEl={anchorElEnabled}
                        open={openEnabled}
                        onClose={handleCloseEnabled}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={() => {handleSelectEnabled("true")}}>true</MenuItem>
                        <MenuItem onClick={() => {handleSelectEnabled("false")}}>false</MenuItem>
                    </Menu>
                </Box>

                <Box sx={{ gridArea: 'provider'}}>
                    Model Provider:
                
                    <Button
                        id="type-example-button"
                        varient="text"
                        size="medium"
                        aria-controls={open ? 'type-example-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClickName}
                    >
                        {type}
                    </Button>
                    
                    <Menu
                        id="type-example-menu"
                        aria-labelledby="type-example-button"
                        anchorEl={anchorElName}
                        open={open}
                        onClose={handleCloseName}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={() => {handleSelectName("openai")}}>openai</MenuItem>
                        <MenuItem onClick={() => {handleSelectName("transformers")}}>transformers</MenuItem>
                    </Menu>
                </Box>

                <Box sx={{ gridArea: 'name' }}>
                    <Input 
                        required 
                        id="outlined-basic" 
                        defaultValue={values["model_name"]} 
                        label="Model Name" 
                        variant="standard" 
                        onChange={handleChange("model_name")}
                        sx={{
                            width: 300,
                            margin: 1,
                        }}
                    />
                </Box>

                <Box sx={{ gridArea: 'apiKey' }}>
                    <Input 
                        required 
                        id="outlined-basic" 
                        defaultValue={values["openai_api_key"]} 
                        label="Openai API Key" 
                        variant="standard" 
                        onChange={handleChange("openai_api_key")}
                        sx={{
                            width: 300,
                            margin: 1,
                        }}
                    />
                </Box>

                <Box sx={{ gridArea: 'apiToken' }}>
                    <Input 
                        required 
                        id="outlined-basic" 
                        defaultValue={values["huggingfacehub_api_token"]} 
                        label="Huggingfacehub API Token" 
                        variant="standard" 
                        onChange={handleChange("huggingfacehub_api_token")}
                        sx={{
                            width: 300,
                            margin: 1,
                        }}
                    />
                </Box>

                <Box sx={{ gridArea: 'temperature' }}>
                    <Input 
                        required 
                        type="number"
                        id="outlined-basic" 
                        defaultValue={values["temperature"]} 
                        label="Temperature" 
                        variant="standard" 
                        onChange={handleChange("temperature")}
                        sx={{
                            width: 300,
                            margin: 1,
                        }}
                    />
                </Box>

                <Box sx={{
                    gridArea: 'instructions', 
                    mt: 1,    
                }}>                    
                    {instructionsList.map(function(instruction, i) {
                        return <NewInstruction 
                            id={i} 
                            globalCallback={handleInstructionChange} 
                            title={instruction[0]} 
                            map_prompt={instruction[1]}
                            combined_prompt={instruction[2]}
                        />
                    })}

                    <div>
                        <Button onClick={addInstruction} variant="contained">Add Instruction</Button>
                    </div>  
                </Box>

                <Box sx={{
                    gridArea: 'trigger',
                    mt: 1,    
                }}>                    
                    {triggers.map(function(trigger, i) {
                        return <NewTrigger 
                            id={i} 
                            globalCallback={handleTriggerChange} 
                            trigger={trigger}
                        />
                    })}
                    
                    <div>
                        <Button onClick={addTrigger} variant="contained">Add Trigger</Button>
                    </div>  
                </Box>

                <Box sx={{ 
                    gridArea: 'submit', 
                    mt: 5
                }}>
                    <Button onClick={submitChanges} variant="contained">Change Settings</Button>
                </Box>

                {submitted ? <Box sx={{
                        gridArea: 'submitted',
                        mt: 5,
                        ml: 2,
                    }}>Submitted!</Box>: null
                }
            </Box>
        </div>
    );
}