import { useEffect, useState } from "react";
import clsx from "clsx";

import {
    AppBar,
    Link,
    Button,
    CssBaseline,
    List,
    ListItem,
    ListItemIcon,
    Drawer,
    Divider,
    IconButton,
    Toolbar,
    Typography,
    Grid
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from '@mui/icons-material/Logout';
import Assignment from "@mui/icons-material/Assignment";
import People from "@mui/icons-material/People";
import Groups from "@mui/icons-material/Groups";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchIcon from '@mui/icons-material/Search';
import Person from "@mui/icons-material/Person";
import PapersTable from "./PapersTable";
import paperApi from "../api/paper";
import SearchComponent from "../components/SearchComponent";
import { usePaperViewStyles } from "../styles/paperViewStyles";
import Copyright from "../components/Copyright";
import { useAuth } from "../useAuth";

const PapersView = () => {
    const classes = usePaperViewStyles();
    const [filter, setFilter] = useState("uploaded");
    const [title, setTitle] = useState("My Papers");
    const [papers, setPapers] = useState([]);
    const { user, logout, admin } = useAuth();

    // get filtered papers on mount and everytime filter state is updated
    useEffect(() => {
        // paperApi.getList(filter).then((paperList) => {
        //     if(paperList){
        //         setPapers(paperList);
        //     }
        //     else {
        //         setPapers([]);
        //     }
        // });
        if (filter === "uploaded") setTitle("My Papers");
        if (filter === "shared") setTitle("Shared With Me");
        if (filter === "published") setTitle("Published Papers");
    }, [filter, user]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, classes.appBarShift)}
            >
                <Toolbar className={classes.toolbar}>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        {title}
                    </Typography>
                    <Button
                        variant="outlined"
                        color="inherit"
                        startIcon={<Person />}
                        href="/userprofile"
                    >
                        {user.firstName}
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<LogoutIcon />}
                        onClick={logout}
                    >
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(
                        classes.drawerPaper
                    ),
                }}
            >
                <div className={classes.colloquium}>
                    <Link href={"/"} underline="hover">
                        Colloquium
                    </Link>
                </div>
                <Divider />
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <Assignment />
                        </ListItemIcon>
                        <Link onClick={() => setFilter("published")} underline="hover">
                            Published
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <People />
                        </ListItemIcon>
                        <Link onClick={() => setFilter("shared")} underline="hover">
                            Shared With Me
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <Link onClick={() => setFilter("uploaded")} underline="hover">
                            My Papers
                        </Link>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Groups />
                        </ListItemIcon>
                        <Link  href="/groups" underline="hover">
                            Groups
                        </Link>
                    </ListItem>
                </List>
                {admin ? <Button
                        variant="outlined"
                        color="inherit"
                        startIcon={<Person />}
                        href="/admin"
                >
                    Administrator Console
                </Button> : null}
            </Drawer>
            <div className={classes.papersTable}>
                <SearchComponent papers={papers} setPapers={setPapers} filter={filter} setFilter={setFilter} />
                {/* <PapersTable papers={papers} filter={filter} user={user} /> */}
            </div>
            <div pt={4}>
                <Copyright />
            </div>
        </div>
    );
};

export default PapersView;
