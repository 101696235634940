import React, { useEffect, useState } from "react";
import {
  Link,
  Grid,
  InputLabel,
  TextField,
  Container,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Switch,
  FormControlLabel
} from "@mui/material";
import { useAuth } from "../useAuth";
import searchApi from "../api/search";
import paperApi from "../api/paper";
import userApi from "../api/user";
import { usePaperTableStyles, usePaperViewStyles } from "../styles/paperViewStyles";

const convertNiceDate = (badDate) => {
    let date = new Date(badDate);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
        dt = "0" + dt;
    }
    if (month < 10) {
        month = "0" + month;
    }

    return year + "-" + month + "-" + dt;
};

// filter refers to the category of the paper in terms of viewability for the user: e.g. Published, Shared, Uploaded

const SearchComponent = ({ papers, setPapers, filter, setFilter }) => {
  const classes = usePaperViewStyles();
  const classes2 = usePaperTableStyles();
  const { user, logout } = useAuth();
  const [sortField, setSortField] = useState(null); // if doesn't work with null, try using ""
  const [sortOrder, setSortOrder] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [searchParam, setSearchParam] = useState("title");
  const [debouncedQuery, setDebouncedQuery] = useState(searchInput);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      userApi.getUserGroups(user.id).then(response => {
        setGroups(response.map(group => group["id"]));
      });
    }

    // Cleanup function to set the flag to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, [user.id]); // Dependency array with user.id to ensure it runs only when user.id changes


  const inputHandler = (e) => {
    setSearchInput(e.target.value);
  };

  const searchParamChange = (event) => {
    setSearchParam(event.target.value);
  };

  const sortFieldChange = (event) => {
    setSortField(event.target.value);
  };

  const sortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  const fetchResults = () => {
    searchApi.getList(debouncedQuery, searchParam, null, null, sortField, sortOrder, filter, user.id, groups).then(response => {
        console.log(response);
        setPapers(response); // updates papers in PapersView
    });
  };

  const handleChangePublish = async (paperID, published) => {
    await paperApi.updateMetadata(paperID, { isPublished: published }) // need to add updateMetadataWithVersion function to search.js
    setPapers(papers.map(paper => {
      if (paper["_id"] === paperID) {
        paper["_source"]["is_published"] = published
      }
      return paper
    }));
    // window.location.replace("/papers");
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      setDebouncedQuery(searchInput);
    }, 500);

    return () => clearTimeout(delay);
  }, [searchInput]);

  useEffect(() => {
      fetchResults();
  }, [debouncedQuery, searchParam, sortField, sortOrder, filter]);

  return (
    <div className={classes2.appBarSpacer}>
      <Container maxWidth="lg" className={classes2.container}>
        <Grid container spacing={3}>

        <Grid item xs={12} md={12} lg={12}>
          <Button variant="contained" color="primary" href="/upload">
              UPLOAD
          </Button>
        </Grid>

        <Grid item xs={10} md={10} lg={10}>
          <InputLabel>Search</InputLabel>
          <TextField
            id="outlined-basic"
            onChange={inputHandler}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item xs={2} md={2} lg={2}>
          <InputLabel>Search By</InputLabel>
          <Select
            value={searchParam}
            label="Search By"
            onChange={searchParamChange}
          >
            <MenuItem value={"title"}>Document Title</MenuItem>
            <MenuItem value={"authors"}>Document Author</MenuItem>
            <MenuItem value={"full_text"}>Document Text</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={2} md={2} lg={2}>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={sortField || ""}
            label="Sort By"
            onChange={sortFieldChange}
          >
            <MenuItem value={null}>None</MenuItem>
            <MenuItem value={"updated_at"}>Date Last Updated</MenuItem>
            <MenuItem value={"created_at"}>Date Created</MenuItem>
            <MenuItem value={"title"}>Document Title</MenuItem>
            <MenuItem value={"authors"}>Document Author</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={2} md={2} lg={2}>
          <InputLabel>Sort Order</InputLabel>
          <Select
            value={sortOrder}
            label="Sort Order"
            onChange={sortOrderChange}
          >
            <MenuItem value={null}>None</MenuItem>
            <MenuItem value={"asc"}>Ascending</MenuItem>
            <MenuItem value={"desc"}>Descending</MenuItem>
          </Select>
        </Grid>

        {(papers.length > 0) ? (
          <Grid item xs={12} md={12} lg={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Document Title</TableCell>
                  <TableCell>Document Author</TableCell>
                  <TableCell>Most Recent Edit</TableCell>
                  {filter === "uploaded" ? (
                    <TableCell>Publish</TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {papers.map((paper, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Link
                        href={`/${paper["_id"]}/${paper["_source"]["version_number"]}`}
                        underline="hover"
                      >
                        {paper["_source"]["title"]}
                      </Link>
                    </TableCell>

                    <TableCell>
                      <React.Fragment>
                        {(() => {
                          let authors = paper["_source"]["authors"];
                          if (typeof authors === "string") {
                            authors = authors.replace(/[\{\}]/g, '').replace(/\[\'/g, '').replace(/\'\]/g, '').replace(/\', \'/g, ', ');
                          } else if (typeof authors === "array") {
                            authors = authors.join(", ");
                          }
                          return authors;
                        })()}
                      </React.Fragment>
                    </TableCell>

                    <TableCell>
                      {convertNiceDate(paper["_source"]["updated_at"])}
                    </TableCell>

                    {filter === "uploaded" ? (
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={paper["_source"]["is_published"]}
                              color="primary"
                              onChange={(e) => {
                                e.preventDefault()
                                handleChangePublish(
                                  paper["_id"],
                                  !paper["_source"]["is_published"] // if paper is not currently published, value sent should be true so it can be set to that in the backend
                                )
                              }}
                            />
                          }
                        />
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        ) : (
          <></>
        )}
        </Grid>
      </Container>
    </div>
  );
  };
  
  export default SearchComponent;
