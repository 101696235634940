import * as React from 'react';
import Input from '@mui/material/TextField';

export default function NewInstruction(props)
{
    const [value, setValue] = React.useState(props.trigger);

    const handleChange = () => (event) => {
        setValue(event.target.value); 
        props.globalCallback(props.id, event.target.value);
    }; 

    return (
        <div>
            <Input  
                id="outlined-basic" 
                defaultValue={value} 
                label="Trigger" 
                variant="standard" 
                onChange={handleChange()}
                sx={{
                    width: 300,
                    margin: 1,
                }}
            />
        </div>
    );
}