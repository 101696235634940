import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import interApi from "../api/interact";
import adminDashApi from "../api/adminDash";
import SummaryConfigMenu from '../components/config-menus/SummaryConfigMenu';
import StandardBotConfigMenu from '../components/config-menus/StandardBotConfigMenu';
import { Typography } from '@mui/material';
import {
    Button,
} from "@mui/material";
// import jsonServerProvider from "ra-data-json-server";

// test data from json place holder site
// const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");

const AdminDashboard = () => {
    const exportRep = () => {
        interApi.exportRep();
    };
    const importRep = () => {
        interApi.importRep();
    };

    // Bot Menu Selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [botMenu, setBotMenu] = React.useState("choose a bot");
    const [botData, setBotData] = React.useState({
        enabled: "",
        provider: "",
        model_name: "",
        open_api_key: "",
        huggingfacehub_api_token: "",
        temperature: "",
    });
    const [loaded, setLoaded] = React.useState(false);

    const open = Boolean(anchorEl);

    // const handleReturn = () => {
    //     window.location.replace("/papers");
    // }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSelect = (option) => {
        setBotMenu(option);
        getBotData(option);
        handleClose();
    };

    const getBotData = async (bot) => {
        setLoaded(false);
        const data = adminDashApi.getList(bot);
        data.then((res) => {
            setBotData(res);
            setLoaded(true);
        });
        // setBotData({
        //     enabled: data["enabled"],
        //     provider: data["provider"],
        //     model_name: data["model_name"],
        //     open_api_key: data["open_api_key"],
        //     huggingfacehub_api_token: data["huggingfacehub_api_token"],
        //     temperature: data["temperature"],
        // });

        return data;
    };
    const renderMenu = () => {
        if (botMenu === "summary") {
            return <SummaryConfigMenu 
                enabled={botData["enabled"].toString()}
                provider={botData["model_provider"]}
                model_name={botData["model_name"]}
                openai_api_key={botData["openai_api_key"]}
                huggingfacehub_api_token={botData["huggingfacehub_api_token"]}
                temperature={botData["temperature"]}
                instructions={botData["instructions"]}
                triggers={botData["triggers"]}
            />;
        }
        else if (botMenu === "elastic") {
            return <StandardBotConfigMenu
                enabled={botData["enabled"].toString()}
                triggers={botData["triggers"]}
                bot="elastic"
            />;
        }
        else if (botMenu === "logger") {
            return <StandardBotConfigMenu
                enabled={botData["enabled"].toString()}
                triggers={botData["triggers"]}
                bot="logger"
            />;
        }
        else if (botMenu === "sentiment") {
            return <StandardBotConfigMenu
                enabled={botData["enabled"].toString()}
                triggers={botData["triggers"]}
                bot="sentiment"
            />;
        }
        else if (botMenu === "spam") {
            return <StandardBotConfigMenu
                enabled={botData["enabled"].toString()}
                triggers={botData["triggers"]}
                bot="spam"
            />;
        }
        else if (botMenu === "tag") {
            return <StandardBotConfigMenu
                enabled={botData["enabled"].toString()}
                triggers={botData["triggers"]}
                bot="tag"
            />;
        }
        else {
            return null;
        }
    }

    return (
        <div className="adminDashboard">
            <Typography
                variant="h3"
                component="div"
                sx={{ flexGrow: 1 }}
            >
                Admin Panel
            </Typography>

            <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
            >
                <Button 
                    variant="contained"
                    color="secondary"
                    href="/"
                >
                    Close Dashboard
                </Button>

            </Typography>
         
            <Button
                sx= {{
                    p: "5",
                }}
                id="bot-menu-button"
                varient="text"
                size="medium"
                aria-controls={open ? 'bot-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {botMenu}
            </Button>

            <Menu
                id="bot-menu"
                aria-labelledby="bot-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {handleSelect("summary")}}>summary</MenuItem>
                <MenuItem onClick={() => {handleSelect("elastic")}}>elastic</MenuItem>
                <MenuItem onClick={() => {handleSelect("logger")}}>logger</MenuItem>
                <MenuItem onClick={() => {handleSelect("sentiment")}}>sentiment</MenuItem>
                <MenuItem onClick={() => {handleSelect("spam")}}>spam</MenuItem>
                <MenuItem onClick={() => {handleSelect("tag")}}>tag</MenuItem>
            </Menu>

            {loaded ? renderMenu() : null} 
        </div>
    );
};

export default AdminDashboard;
