import {api} from "./api.config";
// Search endpoint is on a different port from rest of api, due to being run in flask
const backendUrl = process.env.REACT_APP_SEARCH_API_URL; 
// const userInfo = JSON.parse(localStorage.getItem("userInfo"));// NOTE: idk when the token is used on backend
// const searchaxios = axios.create({
//     baseURL: backendUrl,
//     headers: { Authorization: `Bearer ${userInfo.token}`},
// });

const axiosInstance = api(backendUrl);

/* 
 * Takes params sent from searchView and sends in a postReq to search backend
 * API using axios.post. Currently only makes use of the search input, as the
 * other params we want to send are not collected from frontend.
 * 
 * NOTE: this is untested. I don't have VM access right now as I'm off campus.
 * It should work, as I got most of this stuff by using the other api files as
 * an example.
 */
const searchApi = {
    getList: (searchQuery, field, filter_field, filter_value, sort_field, sort_order, paper_type, user_id, groups) => {

        try {
            return axiosInstance.post("search/", {
                query: searchQuery,
                queryField: field,
                filterField: filter_field,
                filterValue: filter_value,
                sort_field: sort_field,
                sort_order: sort_order,
                paperType: paper_type, // not sure if we have a mapping for this in the ES database (passed from SearchComponent as 'filter')
                creator: user_id,
                groups: groups,
            })
            .then((response) => {
                return response.data;
            });
        } catch (error) {
            console.log(error);
        }
    },
    
    // update a paper metadata with paperInfo => {[authors], isPublished, creatorId, title}
    updateMetadata: (paper_id, paper_info) => {
        try {
            return axiosInstance.post("update/", {
                paperId: paper_id,
                paperInfo: paper_info
            }).then((response) => {
                return response.data;
            });
        } catch (error) {
            console.log(error);
        }
    }
};

export default searchApi;
