import { api } from "./api.config";

const resource = "summary";

const summaryApi = {
    getByPaperVersionId: async (paperId, versionId) => {
        return api().get(`${resource}/${paperId}/${versionId}`).then((response) => response.data);
    },
    // Get a list of all summaries
    // getList: async () => {
    //     return api.get(`${resource}`).then((response) => response.data);
    // },
    // // Get a specific summary by ID
    // getOneById: async (summaryId) => {
    //     return api.get(`${resource}/${summaryId}`).then((response) => response.data);
    // },
    // // Create a new summary with the given content
    // create: async (summaryContent) => {
    //     return api.post(`${resource}`, { content: summaryContent }).then((response) => response.data);
    // },
    // // Update an existing summary by ID with new content
    // update: async (summaryId, summaryContent) => {
    //     return api.put(`${resource}/${summaryId}`, { content: summaryContent }).then((response) => response.data);
    // },
    // // Delete a summary by ID
    // delete: async (summaryId) => {
    //     return api.delete(`${resource}/${summaryId}`).then((response) => response.data);
    // },
    // // Fetch the summary based on provided text
    // summarizeText: async (text) => {
    //     return api.post(`${resource}/summarize-text`, { text: text }).then((response) => response.data);
    // },
    // // Set a user's preferred summarization model
    // setUserModel: async (modelId) => {
    //     return api.post(`${resource}/set-model`, { modelId: modelId }).then((response) => response.data);
    // },
    // // Get a user's available models
    // getUserModels: async () => {
    //     return api.get(`${resource}/get-models`).then((response) => response.data);
    // },
    // // Create a summary with custom instructions
    // createWithInstructions: async (summaryContent, instructions) => {
    //     return api.post(`${resource}/with-instructions`, { content: summaryContent, instructions: instructions }).then((response) => response.data);
    // },
    // // Fetch the aggregate summary of a document broken into chunks
    // getAggregateSummary: async (documentId) => {
    //     return api.get(`${resource}/aggregate-summary/${documentId}`).then((response) => response.data);
    // },
    // // Update the summarizeText and summarizeUrl methods to allow custom instructions
    // summarizeText: async (text, instructions = null) => {
    //     return api.post(`${resource}/summarize-text`, { text: text, instructions: instructions }).then((response) => response.data);
    // },
    // summarizeUrl: async (url, instructions = null) => {
    //     return api.post(`${resource}/summarize-url`, { url: url, instructions: instructions }).then((response) => response.data);
    // },
};

export default summaryApi;
