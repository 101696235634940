import {api} from "./api.config";

const backendUrl = process.env.REACT_APP_SEARCH_API_URL; 
const axiosInstance = api(backendUrl);

const adminDashApi = {
    submit: (config, bot) => {
        try {
            return axiosInstance.post("adminChange/", {
                config: config,
                bot: bot,
            })
            .then((response) => {
                return response.data;
            });
        } catch (error) {
            console.log(error);
        }
    },

    getList: (botName) => {
        try {
            return axiosInstance.get("adminChange/configList?botName=" + botName)
            .then((response) => {
                return response.data;
            });
        } catch (error) {
            console.log(error);
        }
    }
};

export default adminDashApi;
