import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/material';
import NewTrigger from './NewTrigger';
import adminDashApi from '../../api/adminDash';

//import adminDashApi from '../../api/adminDash';

export default function StandardBotConfigMenu(props) {
    const [anchorElEnabled, setAnchorElEnabled] = React.useState(null);
    const [enabled, setEnabled] = React.useState(props.enabled);
    const [triggers, setTriggers] = React.useState(props.triggers);
    const [submitted, setSubmitted] = React.useState(false);

    const openEnabled = Boolean(anchorElEnabled);

    // Bot Enabled Handlers
    const handleClickEnabled = (event) => {
        setAnchorElEnabled(event.currentTarget);
    };
    const handleCloseEnabled = () => {
        setAnchorElEnabled(null);
    };
    const handleSelectEnabled = (option) => {
        setEnabled(option);
        handleCloseEnabled();
    }

    // Trigger Handlers
    const addTrigger = () => {
        setTriggers([...triggers, ""]);
    };

    const handleTriggerChange = (id, newTrigger) => {
        let newTriggers = [...triggers];
        newTriggers[id] = newTrigger;

        setTriggers(newTriggers);
    }

    const submitChanges = async () => {
        const changes = {
            enabled: enabled,
            triggers,
        };
        adminDashApi.submit(changes, props.bot).then(() => setSubmitted(true));
    };

    return (
        <div className="adminDashboard">
            <Box 
                sx={{
                    m: 2,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gridTemplateRows: 'auto',
                    gridTemplateAreas: `
                        "enabled . . . "
                        "trigger . . ."
                        "submit submitted . ."
                    `,
                }}
            >
                <Box sx={{ gridArea: 'enabled'}}>
                    Enabled:
                    
                    <Button
                        id="type-example-button"
                        varient="text"
                        size="medium"
                        aria-controls={openEnabled ? 'type-example-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openEnabled ? 'true' : undefined}
                        onClick={handleClickEnabled}
                    >
                        {enabled}
                    </Button>
                        
                    <Menu
                        id="type-example-menu"
                        aria-labelledby="type-example-button"
                        anchorEl={anchorElEnabled}
                        open={openEnabled}
                        onClose={handleCloseEnabled}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={() => {handleSelectEnabled("true")}}>true</MenuItem>
                        <MenuItem onClick={() => {handleSelectEnabled("false")}}>false</MenuItem>
                    </Menu>
                </Box>

                <Box sx={{
                    gridArea: 'trigger', 
                    mt: 1,    
                }}>                    
                    {triggers.map(function(trigger, i) {
                        return <NewTrigger 
                            id={i} 
                            globalCallback={handleTriggerChange} 
                            trigger={trigger}
                        />
                    })}
                    
                    <div>
                        <Button onClick={addTrigger} variant="contained">Add Trigger</Button>
                    </div>  
                </Box>

                <Box sx={{ 
                    gridArea: 'submit', 
                    mt: 5 
                }}>
                    <Button onClick={submitChanges} variant="contained">Change Settings</Button>
                </Box>

                {submitted ? <Box sx={{
                        gridArea: 'submitted',
                        mt: 5,
                        ml: 2,
                    }}>Submitted!</Box> : null
                }
            </Box>
        </div>
    );
}